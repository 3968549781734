<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="breadcrumb">
      <h1>Modificar Medico</h1>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="col-sm-5">
              <button
                type="submit"
                class="btn bg-custom-green mr-2 rounded"
                @click="guardarMedico"
              >Guardar
              </button>
              <router-link :to="{ name: 'medico-index' }">
                <button class="btn btn-white border border-light rounded">Regresar</button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 py-3 px-2">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-2">
                      <div class="col-sm-3 pr-0 text-right">
                        <label class="col-form-label">Nombre y Apellido:</label>
                      </div>
                      <div class="col-sm-7">
                        <input type="text" class="form-control" v-model="nombre" autocomplete="off">
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 pr-0 text-right">
                        <label class="col-form-label">Email:</label>
                      </div>
                      <div class="col-sm-7">
                        <input type="email" class="form-control" v-model="email" autocomplete="off">
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 pr-0 text-right">
                        <label class="col-form-label">Direccion:</label>
                      </div>
                      <div class="col-sm-7">
                        <input type="text"
                          class="form-control"
                          v-model="direccion"
                          autocomplete="off">
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 pr-0 text-right">
                        <label class="col-form-label">Especialidad:</label>
                      </div>
                      <div class="col-sm-7">
                        <vue-tags-input
                          v-model="especialidadInput"
                          :tags="especialidadesSelected"
                          class="tag-custom text-15"
                          :autocomplete-items="filteredItems"
                          @tags-changed="validarEspecialidad"
                          placeholder="Selecciona las Especialidades"
                        />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 pr-0 text-right">
                        <label class="col-form-label">Tipo Configuración:</label>
                      </div>
                      <div class="col-sm-7">
                        <div class="text-left pt-2">
                          <label class="switch switch-success mr-3">
                            <input
                              type="checkbox"
                              id="filter"
                              :checked="tipoConfiguracionFactura"
                              v-model="tipoConfiguracionFactura"
                            >
                            <span class="slider"></span>
                            <span>{{ labelTipoConfiguracion }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-2">
                      <div class="col-sm-3 text-right">
                        <label class="col-form-label">Fecha de Nacimiento:</label>
                      </div>
                      <div class="col-sm-4">
                        <date-picker
                          class="col-sm-10 px-0"
                          v-model="fechaNacimiento"
                          lang="es"
                          format="DD/MM/YYYY"
                          type="date"
                          value-type="date"
                          @input="getAge(fechaNacimiento)"
                        ></date-picker>
                      </div>
                      <div class="col-sm-2 pl-0">
                        <label class="col-form-label">{{edadActual}}</label>
                      </div>
                      <button
                        v-if="!isDisableTratamiento"
                        class="col-sm-2 btn"
                        :class="{'btn-warning': !isAtencion, 'btn-success': isAtencion}"
                        v-b-modal.modal-atencion
                      >Horario
                      </button>
                      <b-modal size="lg" id="modal-atencion" title="Dias de Atencion" hide-footer>
                        <!-- Turnos -->
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="col-sm-12 text-center"><b>Primer Turno</b></div>
                            <!-- Ingreso y Salida -->
                            <div class="row mb-1">
                              <div class="col-sm-1"></div>
                              <div class="col-sm-2"></div>
                              <div class="col-sm-3 text-center"><b>Ingreso</b></div>
                              <div class="col-sm-3 text-center"><b>Salida</b></div>
                              <div class="col-sm-3"></div>
                            </div>
                            <!-- Fin -->
                            <!-- Lunes -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="checkLunesPrimerTurno"
                                    v-model="diasAtencion.primerTurno.lunes.estado"
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkLunesPrimerTurno">
                                  Lunes:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                              <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.lunes.ingreso"
                                :disabled="!diasAtencion.primerTurno.lunes.estado"
                                @change="restarHora(diasAtencion.primerTurno.lunes,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.lunes.salida"
                                :disabled="!diasAtencion.primerTurno.lunes.estado"
                                @change="restarHora(diasAtencion.primerTurno.lunes,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.primerTurno.lunes.totalTiempo}}
                              </div>
                            </div>
                            <!-- Martes -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkMartesPrimerTurno"
                                  v-model="diasAtencion.primerTurno.martes.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkMartesPrimerTurno">
                                  Martes:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                              <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.martes.ingreso"
                                :disabled="!diasAtencion.primerTurno.martes.estado"
                                @change="restarHora(diasAtencion.primerTurno.martes,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.martes.salida"
                                :disabled="!diasAtencion.primerTurno.martes.estado"
                                @change="restarHora(diasAtencion.primerTurno.martes,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.primerTurno.martes.totalTiempo}}
                              </div>
                            </div>
                            <!-- Miercoles -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkMiercolesPrimerTurno"
                                  v-model="diasAtencion.primerTurno.miercoles.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkMiercolesPrimerTurno">
                                  Miercoles:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                              <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.miercoles.ingreso"
                                :disabled="!diasAtencion.primerTurno.miercoles.estado"
                                @change="restarHora(diasAtencion.primerTurno.miercoles,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.miercoles.salida"
                                :disabled="!diasAtencion.primerTurno.miercoles.estado"
                                @change="restarHora(diasAtencion.primerTurno.miercoles,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.primerTurno.miercoles.totalTiempo}}
                              </div>
                            </div>
                            <!-- Jueves -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkJuevesPrimerTurno"
                                  v-model="diasAtencion.primerTurno.jueves.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkJuevesPrimerTurno">
                                  Jueves:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                              <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.jueves.ingreso"
                                :disabled="!diasAtencion.primerTurno.jueves.estado"
                                @change="restarHora(diasAtencion.primerTurno.jueves,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.jueves.salida"
                                :disabled="!diasAtencion.primerTurno.jueves.estado"
                                @change="restarHora(diasAtencion.primerTurno.jueves,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.primerTurno.jueves.totalTiempo}}
                              </div>
                            </div>
                            <!-- Viernes -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkViernesPrimerTurno"
                                  v-model="diasAtencion.primerTurno.viernes.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkViernesPrimerTurno">
                                  Viernes:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.viernes.ingreso"
                                :disabled="!diasAtencion.primerTurno.viernes.estado"
                                @change="restarHora(diasAtencion.primerTurno.viernes,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.viernes.salida"
                                :disabled="!diasAtencion.primerTurno.viernes.estado"
                                @change="restarHora(diasAtencion.primerTurno.viernes,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.primerTurno.viernes.totalTiempo}}
                              </div>
                            </div>
                            <!-- Sabado -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkSabadoPrimerTurno"
                                  v-model="diasAtencion.primerTurno.sabado.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkSabadoPrimerTurno">
                                  Sabado:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.sabado.ingreso"
                                :disabled="!diasAtencion.primerTurno.sabado.estado"
                                @change="restarHora(diasAtencion.primerTurno.sabado,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.sabado.salida"
                                :disabled="!diasAtencion.primerTurno.sabado.estado"
                                @change="restarHora(diasAtencion.primerTurno.sabado,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.primerTurno.sabado.totalTiempo}}
                              </div>
                            </div>
                            <!-- Domingo -->
                            <div class="row mb-3">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkDomingoPrimerTurno"
                                  v-model="diasAtencion.primerTurno.domingo.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkDomingoPrimerTurno">
                                  Domingo:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.domingo.ingreso"
                                :disabled="!diasAtencion.primerTurno.domingo.estado"
                                @change="restarHora(diasAtencion.primerTurno.domingo,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.primerTurno.domingo.salida"
                                :disabled="!diasAtencion.primerTurno.domingo.estado"
                                @change="restarHora(diasAtencion.primerTurno.domingo,
                                'primerTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.primerTurno.domingo.totalTiempo}}
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="col-sm-12 text-center"><b>Segundo Turno</b></div>
                            <div class="row mb-1">
                              <div class="col-sm-1"></div>
                              <div class="col-sm-2"></div>
                              <div class="col-sm-3 text-center"><b>Ingreso</b></div>
                              <div class="col-sm-3 text-center"><b>Salida</b></div>
                              <div class="col-sm-3"></div>
                            </div>
                            <!-- Lunes -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkLunesSegundoTurno"
                                  v-model="diasAtencion.segundoTurno.lunes.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkLunesSegundoTurno">
                                  Lunes:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                              <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.lunes.ingreso"
                                :disabled="!diasAtencion.segundoTurno.lunes.estado"
                                @change="restarHora(diasAtencion.segundoTurno.lunes,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.lunes.salida"
                                :disabled="!diasAtencion.segundoTurno.lunes.estado"
                                @change="restarHora(diasAtencion.segundoTurno.lunes,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.segundoTurno.lunes.totalTiempo}}
                              </div>
                            </div>
                            <!-- Martes -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkMartesSegundoTurno"
                                  v-model="diasAtencion.segundoTurno.martes.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkMartesSegundoTurno">
                                  Martes:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                              <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.martes.ingreso"
                                :disabled="!diasAtencion.segundoTurno.martes.estado"
                                @change="restarHora(diasAtencion.segundoTurno.martes,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.martes.salida"
                                :disabled="!diasAtencion.segundoTurno.martes.estado"
                                @change="restarHora(diasAtencion.segundoTurno.martes,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.segundoTurno.martes.totalTiempo}}
                              </div>
                            </div>
                            <!-- Miercoles -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkMiercolesSegundoTurno"
                                  v-model="diasAtencion.segundoTurno.miercoles.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkMiercolesSegundoTurno">
                                  Miercoles:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                              <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.miercoles.ingreso"
                                :disabled="!diasAtencion.segundoTurno.miercoles.estado"
                                @change="restarHora(diasAtencion.segundoTurno.miercoles,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.miercoles.salida"
                                :disabled="!diasAtencion.segundoTurno.miercoles.estado"
                                @change="restarHora(diasAtencion.segundoTurno.miercoles,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.segundoTurno.miercoles.totalTiempo}}
                              </div>
                            </div>
                            <!-- Jueves -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkJuevesSegundoTurno"
                                  v-model="diasAtencion.segundoTurno.jueves.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkJuevesSegundoTurno">
                                  Jueves:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                              <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.jueves.ingreso"
                                :disabled="!diasAtencion.segundoTurno.jueves.estado"
                                @change="restarHora(diasAtencion.segundoTurno.jueves,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.jueves.salida"
                                :disabled="!diasAtencion.segundoTurno.jueves.estado"
                                @change="restarHora(diasAtencion.segundoTurno.jueves,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.segundoTurno.jueves.totalTiempo}}
                              </div>
                            </div>
                            <!-- Viernes -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkViernesSegundoTurno"
                                  v-model="diasAtencion.segundoTurno.viernes.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkViernesSegundoTurno">
                                  Viernes:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.viernes.ingreso"
                                :disabled="!diasAtencion.segundoTurno.viernes.estado"
                                @change="restarHora(diasAtencion.segundoTurno.viernes,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.viernes.salida"
                                :disabled="!diasAtencion.segundoTurno.viernes.estado"
                                @change="restarHora(diasAtencion.segundoTurno.viernes,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.segundoTurno.viernes.totalTiempo}}
                              </div>
                            </div>
                            <!-- Sabado -->
                            <div class="row mb-1">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkSabadoSegundoTurno"
                                  v-model="diasAtencion.segundoTurno.sabado.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkSabadoSegundoTurno">
                                  Sabado:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.sabado.ingreso"
                                :disabled="!diasAtencion.segundoTurno.sabado.estado"
                                @change="restarHora(diasAtencion.segundoTurno.sabado,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.sabado.salida"
                                :disabled="!diasAtencion.segundoTurno.sabado.estado"
                                @change="restarHora(diasAtencion.segundoTurno.sabado,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.segundoTurno.sabado.totalTiempo}}
                              </div>
                            </div>
                            <!-- Domingo -->
                            <div class="row mb-3">
                              <div class="col-sm-1 text-right pr-0">
                                <div class="form-check pt-1">
                                  <input class="form-check-input" type="checkbox"
                                  id="checkDomingoSegundoTurno"
                                  v-model="diasAtencion.segundoTurno.domingo.estado">
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <label class="col-form-label" for="checkDomingoSegundoTurno">
                                  Domingo:
                                </label>
                              </div>
                              <div class="col-sm-3 pr-0">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.domingo.ingreso"
                                :disabled="!diasAtencion.segundoTurno.domingo.estado"
                                @change="restarHora(diasAtencion.segundoTurno.domingo,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-1">
                                <input type="time" class="form-control pl-1 pr-2"
                                v-model="diasAtencion.segundoTurno.domingo.salida"
                                :disabled="!diasAtencion.segundoTurno.domingo.estado"
                                @change="restarHora(diasAtencion.segundoTurno.domingo,
                                'segundoTurno')">
                              </div>
                              <div class="col-sm-3 pl-0">
                                {{diasAtencion.segundoTurno.domingo.totalTiempo}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- turnos -->
                        <!-- Boton Guardar -->
                        <div class="row">
                          <div class="col-sm-12 text-right">
                            <button
                              class="btn btn-success"
                              @click="$bvModal.hide('modal-atencion')"
                            >Guardar
                            </button>
                          </div>
                        </div>
                        <!-- Fin Boton -->
                      </b-modal>
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 text-right">
                        <label class="col-form-label">N° Colegio Medico:</label>
                      </div>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          v-model="colegio_medico"
                          autocomplete="off">
                      </div>
                      <div class="col-sm-2 pl-0">
                        <b-form-select v-model="generoSelected" :options="genero">
                        </b-form-select>
                      </div>
                      <label
                        for="foto"
                        class="col-sm-2 my-0 btn"
                        :class="{'btn-warning': !isFoto, 'btn-success': isFoto}"
                      >Foto</label>
                      <input type="file" id="foto" @change="perfils" class="file-upload" />
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 text-right">
                        <label class="col-form-label">N° Matricula:</label>
                      </div>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          v-model="matricula"
                          autocomplete="off"
                        >
                      </div>
                      <div class="col-sm-2 pl-0">
                        <input
                          type="text"
                          class="form-control"
                          v-model="carnet_identidad"
                          placeholder="C. I."
                          autocomplete="off">
                      </div>
                      <button
                        v-if="imagenPerfil"
                        type="button"
                        class="col-sm-2 btn"
                        v-b-modal.modalfoto
                      >Ver Foto
                      </button>
                      <b-modal size="md" id="modalfoto" title="Foto de Perfil" hide-footer>
                        <img :src="imagenPerfil" alt="Foto Perfil">
                      </b-modal>
                    </div>
                    <div class="row mb-2">
                      <div class="col-sm-3 text-right">
                        <label class="col-form-label">Celular:</label>
                      </div>
                      <div class="col-sm-2 py-1">
                        <multiselect2
                          v-model="paisSelected"
                          :options="paises"
                          :allow-empty="false"
                          :show-labels="false"
                          :searchable="false"
                          :close-on-select="true"
                          label="title"
                          track-by="title"
                          @input="setCodigoPais"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <img class="option__image" :src="props.option.img"
                              :alt="props.option.title"
                              width="26px">
                          </template>
                          <template slot="option" slot-scope="props">
                            <img class="option__image" :src="props.option.img"
                              :alt="props.option.title" width="26px">
                          </template>
                        </multiselect2>
                      </div>
                      <div class="col-sm-4 pl-0 py-1">
                        <div class="input-group input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{codigoPais}}</span>
                          </div>
                          <input type="text" class="form-control text-center" v-model="celular"
                          autocomplete="off" @keydown="validarCaracter" @blur="reValidarCelular">
                        </div>
                      </div>
                      <div class="col-sm-2 px-0 text-center">
                        <v-swatches v-model="inputColor"
                        swatch-size="26"
                        :swatches="swatches"
                        shapes="circles">
                        </v-swatches>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2 px-3" v-if="isDisableTratamiento">
              <div class="col-sm-12 pt-3">
                <h4><b>Turno Para Tratamiento</b></h4>
              </div>
              <div class="col-sm-12 bg-custom-gray rounded py-3">
                <div class="row">
                  <button
                    class="btn col-sm-2 mx-5"
                    :class="tablaHonorarioMedicoFiltered.length>0? 'btn-success':'btn-warning'"
                    @click="openModalHonorario"
                  >
                    Honorario
                  </button>
                  <ModalHonorarioMedico />
                  <button
                    class="btn col-sm-2 mr-5"
                    :class="turno1Validate? 'btn-success':'btn-warning'"
                    @click="openModalTurno1"
                  >
                    Turno 1
                  </button>
                  <modalTurno1 />
                  <button
                    class="btn col-sm-2"
                    :class="turno2Validate? 'btn-success':'btn-warning'"
                    @click="openModalTurno2"
                  >
                    Turno 2
                  </button>
                  <modalTurno2 />
                </div>
              </div>
            </div>
            <div class="row mx-0" v-if="hasPermissionMedicoTercero">
              <div class="col-sm-12 pt-3">
                <h4><b>Facturacion Terceros</b></h4>
              </div>
            </div>
            <div class="row mb-3 mx-0" v-if="hasPermissionMedicoTercero">
              <div class="col-sm-12 bg-custom-green rounded-box py-2">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row mb-2">
                      <div class="col-sm-3 pr-0 text-right">
                        <label class="col-form-label">Actividad Economica:</label>
                      </div>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="actividad_economica"
                          autocomplete="off"
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-3 pr-0 text-right">
                        <label class="col-form-label">Razon Social:</label>
                      </div>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="razon_social"
                          autocomplete="off"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mb-2">
                      <div class="col-sm-1 pr-0 text-right">
                        <label class="col-form-label">Ciudad:</label>
                      </div>
                      <div class="col-sm-4">
                        <multiselect2
                          v-model="ciudadSelected"
                          :options="listaCiudades"
                          placeholder=""
                          label="nombre"
                          track-by="id"
                          select-label=""
                          deselect-label="X"
                          :allow-empty="false"
                        >
                          <span slot="noResult">Sin Resultados</span>
                          <span slot="noOptions">Lista Vacia</span>
                        </multiselect2>
                      </div>
                      <div class="col-sm-2 text-right pr-0">
                        <label class="col-form-label">Sucursal:</label>
                      </div>
                      <div class="col-sm-4 pl-1">
                        <b-form-select v-model="sucursalSelected" :options="sucursales">
                        </b-form-select>
                      </div>
                      <!-- <div class="col-sm-4 text-right">
                          <label
                          for="file-upload"
                          class="btn custom-file-upload"
                          :class="{'btn-warning': !isLogo, 'btn-success': isLogo}">Logo</label>
                          <input
                          type="file"
                          id="file-upload"
                          @change="fileUploads" class="file-upload" />
                        </div> -->
                    </div>
                    <div class="row">
                      <div class="col-sm-1 pr-0 text-right">
                        <label class="col-form-label">Nit:</label>
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control" v-model="nit"
                          @keydown="validarCaracter" @blur="reValidar" autocomplete="off">
                      </div>
                      <div v-if="imagenLogo" class="col-sm-4 text-right">
                        <button type="button" class="btn custom-file-upload"
                        v-b-modal.modallogo>Ver Logo</button>
                        <b-modal size="md" id="modallogo" title="Logo de Factura" hide-footer>
                          <img :src="imagenLogo" alt="Foto Perfil">
                        </b-modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <b-tabs
                  nav-class="tabs_custom_border"
                  active-nav-item-class="tab_custom_border"
                >
                  <b-tab
                    title="Factura Terceros"
                    title-item-class="font-weight-bold"
                    active
                    :disabled="tipoConfiguracionFactura === false"
                  >
                    <div class="row mb-3 py-2 border border-secondary" v-if="hasPermissionMedicoTercero">
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label>N° de Autorización:</label>
                          <input
                            type="text"
                            class="form-control"
                            :readonly="isReadonly"
                            v-model="numero_autorizacion"
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label>LLave de Dosificación:</label>
                          <input
                            type="text"
                            class="form-control"
                            :readonly="isReadonly"
                            v-model="llave_dosificacion"
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label>Leyenda:</label>
                          <input
                            type="text"
                            class="form-control"
                            :readonly="isReadonly"
                            v-model="leyenda"
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label>Fecha:</label>
                          <date-picker
                            class="col-sm-10 px-0"
                            v-model="fechaFacturacionTerceros"
                            lang="es"
                            :disabled="isReadonly"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="format"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-sm-1 pl-0">
                        <div class="form-group">
                          <label>Inicio:</label>
                          <input
                            type="text"
                            class="form-control"
                            :readonly="isReadonly"
                            v-model="numero_inicio"
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-sm-2 pt-3">
                        <button
                          class="btn btn-warning"
                          :disabled="isReadonly"
                          @click="listTablas()"
                        >Agregar
                        </button>
                      </div>
                    </div>
                    <b-table
                      class="table table-hover"
                      :fields="fields"
                      :items="listaTabla"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      v-if="hasPermissionMedicoTercero"
                    >
                      <template #cell(editar)="row">
                        <i
                          class="nav-icon i-Pen-5 text-success font-weight-bold"
                          :class="{'d-none': row.item.isEditData}"
                          style="cursor: pointer"
                          @click="editItems(row.item.numero - 1)"></i>
                      </template>
                      <template #cell(eliminar)="rows">
                        <i
                          class="nav-icon i-Close-Window text-danger font-weight-bold"
                          :class="{'d-none': rows.item.isEditData || isDisabledDelete}"
                          style="cursor: pointer"
                          @click="deleteItems(rows.item.numero - 1)"></i>
                      </template>
                    </b-table>
                  </b-tab>
                  <b-tab
                    title="Factura Electrónica"
                    title-item-class="font-weight-bold"
                    :disabled="tipoConfiguracionFactura"
                  >
                    <div class="row mb-3 py-2 border border-secondary">
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label>Doc. Sector:
                            <span class="text-danger">*</span>
                          </label>
                          <multiselect2
                            v-model="docSectorSelected"
                            :options="listaDocsSector"
                            :disabled="isDisabledFormFacturaElectronica"
                            placeholder=""
                            label="emisor_descripcion"
                            track-by="id"
                            select-label=""
                            deselect-label="X">
                            <span slot="noResult">Sin Resultados</span>
                            <span slot="noOptions">Lista Vacia</span>
                          </multiselect2>
                        </div>
                      </div>
                      <div class="col-sm-2 pl-0">
                        <div class="form-group">
                          <label>Tipo de Documentos:
                            <span class="text-danger">*</span>
                          </label>
                          <multiselect2
                            v-model="docFacturacionSelected"
                            :options="listaDocsFacturacion"
                            :disabled="isDisabledFormFacturaElectronica"
                            placeholder=""
                            label="descripcion"
                            track-by="id"
                            select-label=""
                            deselect-label="X">
                            <span slot="noResult">Sin Resultados</span>
                            <span slot="noOptions">Lista Vacia</span>
                          </multiselect2>
                        </div>
                      </div>
                      <div class="col-sm-1 px-0">
                        <div class="form-group">
                          <label>Secuencia:
                            <span class="text-danger">*</span>
                          </label>
                          <multiselect2
                            v-model="secuenciaSelected"
                            :options="listaSecuencias"
                            :disabled="isDisabledFormFacturaElectronica"
                            placeholder=""
                            label="nombre"
                            track-by="id"
                            select-label=""
                            deselect-label="X">
                            <span slot="noResult">Sin Resultados</span>
                            <span slot="noOptions">Lista Vacia</span>
                          </multiselect2>
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label>Fecha:
                            <span class="text-danger">*</span>
                          </label>
                          <date-picker
                            class="col-sm-12 px-0"
                            v-model="fechaFacturaElectronica"
                            :disabled="isDisabledFormFacturaElectronica"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="format"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-sm-1 px-0">
                        <div class="form-group">
                          <label>N° Inicial:
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="numeroInicialFacturaElectronica"
                            :disabled="isDisabledFormFacturaElectronica"
                            autocomplete="off"
                          >
                        </div>
                      </div>
                      <div class="col-sm-2">
                        <div class="form-group">
                          <label>Tamaño:
                            <span class="text-danger">*</span>
                          </label>
                          <multiselect2
                            v-model="tipoHojaSelected"
                            :options="listaTipoHojas"
                            :disabled="isDisabledFormFacturaElectronica"
                            placeholder=""
                            label="nombre"
                            track-by="id"
                            select-label=""
                            deselect-label="X">
                            <span slot="noResult">Sin Resultados</span>
                            <span slot="noOptions">Lista Vacia</span>
                          </multiselect2>
                        </div>
                      </div>
                      <div class="col-sm-1 pt-3">
                        <button
                          class="btn btn-warning btn-block px-0"
                          @click="addOrUpdateConfigFacturaElectronica"
                          :disabled="isDisabledFormFacturaElectronica"
                        >Agregar
                        </button>
                      </div>
                    </div>
                    <b-table
                      class="table table-hover"
                      :fields="fieldsFacturaElectronica"
                      :items="itemsFacturaElectronica"
                    >
                      <template #cell(editar)="row">
                        <i
                          class="nav-icon i-Pen-5 text-success font-weight-bold"
                          :class="{'d-none': row.item._rowVariant === 'danger'}"
                          style="cursor: pointer"
                          @click="editItemFacturaElectronica(row.item)"
                        ></i>
                      </template>
                      <template #cell(eliminar)="row">
                        <i
                          class="nav-icon i-Close-Window text-danger font-weight-bold"
                          :class="{'d-none': row.item._rowVariant === 'danger'}"
                          style="cursor: pointer"
                          @click="deleteItemFacturaElectronica(row.index)"
                        ></i>
                      </template>
                    </b-table>
                    <div class="row">
                      <div class="col-sm-12 text-right">
                        <button
                          class="btn"
                          :class="getClassForBtnSyncSiat"
                          @click="$bvModal.show('modal-configuracionMedico')"
                        >
                          Sincronizar Siat
                        </button>
                        <modalConfiguracionMedico
                          :nameMedico="nombre"
                          :listaActividadesEconomicas="listaActividadesEconomicas"
                          :listaProductosServicios="listaProductosServicios"
                          :listaMonedas="listaMonedas"
                          :listaSucursales="listaSucursales"
                          :listaPuntosVenta="listaPuntosVenta"
                          :listaUM="listaUM"
                          :listaPaises="listaPaises"
                          :listaMedioPagos="listaMedioPagosErp"
                          :listaMedioPagosSiat="listaMedioPagosSiat"
                          :listaTipoDocumentos="listaTipoDocumentosErp"
                          :listaTipoDocumentosSiat="listaTipoDocumentosSiat"
                          :configuracionMedicoInput="configuracionMedicoModal"
                          :dataTipoDocumentoMedicoInput="dataTipoDocumentoMedicoModal"
                          :dataMedioPagoInput="dataMedioPagosModal"
                          @updateDataConfiguracionMedico="configuracionMedicoModal = $event"
                          @updateDataTipoDocumento ="dataTipoDocumentoMedicoModal = $event"
                          @updateDataMedioPago ="dataMedioPagosModal = $event"
                        />
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Multiselect2 from '@/components/util/vue-multiselect2';
import DatePicker from 'vue2-datepicker';
import VSwatches from 'vue-swatches';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import 'vue-swatches/dist/vue-swatches.css';
import { mapFields } from 'vuex-map-fields';
import ModalHonorarioMedico from '../components/TheModalHonorarioMedico.vue';
import modalTurno1 from '../components/modalTurno1.vue';
import modalTurno2 from '../components/modalTurno2.vue';
import modalConfiguracionMedico from '../components/modalConfiguracionMedico.vue';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'edit',
  components: {
    Multiselect2,
    DatePicker,
    VSwatches,
    ModalHonorarioMedico,
    modalTurno1,
    modalTurno2,
    modalConfiguracionMedico,
  },
  data() {
    return {
      isLoading: true,
      paisSelected: { title: 'Bo', codigo: '+591', img: 'https://cdn.countryflags.com/thumbs/bolivia/flag-400.png' },
      codigoPais: '',
      celular: '',
      paises: [
        { title: 'Bo', codigo: '+591', img: 'https://cdn.countryflags.com/thumbs/bolivia/flag-400.png' },
        { title: 'Br', codigo: '+55', img: 'https://cdn.countryflags.com/thumbs/brazil/flag-400.png' },
        { title: 'Ar', codigo: '+54', img: 'https://cdn.countryflags.com/thumbs/argentina/flag-400.png' },
        { title: 'Es', codigo: '+34', img: 'https://cdn.countryflags.com/thumbs/spain/flag-400.png' },
        { title: 'Pe', codigo: '+51', img: 'https://cdn.countryflags.com/thumbs/peru/flag-400.png' },
        { title: 'Co', codigo: '+57', img: 'https://cdn.countryflags.com/thumbs/colombia/flag-400.png' },
      ],
      inputColor: null,
      fechaNacimiento: null,
      fechaFacturacionTerceros: null,
      edadActual: null,
      especialidadInput: '',
      especialidadesSelected: [],
      especialidad_id: [],
      generoSelected: 2,
      ciudadSelected: null,
      nit: '',
      genero: [
        { value: 'H', text: 'Hombre' },
        { value: 'M', text: 'Mujer' },
      ],
      listaCiudades: [],
      especialidades: [],
      fields: [
        {
          key: 'numero', label: 'N°', thStyle: { width: '5%' },
        },
        {
          key: 'numero_autorizacion', label: 'N° Autorizacion', class: 'text-center', thStyle: { width: '11%' },
        },
        {
          key: 'llave_dosificacion', label: 'LLave de Dosificación', class: 'text-center', thStyle: { width: '29%' },
        },
        {
          key: 'leyenda', label: 'Leyenda', class: 'text-center', thStyle: { width: '17%' },
        },
        {
          key: 'fecha_limite_emision', label: 'Fecha', class: 'text-center', thStyle: { width: '12%' },
        },
        {
          key: 'numero_inicial', label: 'N° Inicial', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'numero_actual', label: 'N° Actual', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
      ],
      listaTabla: [],
      diasAtencion: {
        primerTurno: {
          lunes: {
            clave: 'lunes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          martes: {
            clave: 'martes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          miercoles: {
            clave: 'miercoles',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          jueves: {
            clave: 'jueves',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          viernes: {
            clave: 'viernes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          sabado: {
            clave: 'sabado',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          domingo: {
            clave: 'domingo',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
        },
        segundoTurno: {
          lunes: {
            clave: 'lunes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          martes: {
            clave: 'martes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          miercoles: {
            clave: 'miercoles',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          jueves: {
            clave: 'jueves',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          viernes: {
            clave: 'viernes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          sabado: {
            clave: 'sabado',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          domingo: {
            clave: 'domingo',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
        },
      },
      swatches: [
        { color: '#1c5abd', disabled: true },
        { color: '#ffdba3', disabled: false },
        { color: '#2ECC70', disabled: true },
        { color: '#1FBC9C', disabled: false },
        { color: '#27AF60', disabled: true },
        { color: '#3398DB', disabled: false },
        { color: '#2980B9', disabled: false },
        { color: '#A463BF', disabled: false },
        { color: '#8E43AD', disabled: false },
        { color: '#3D556E', disabled: false },
        { color: '#222F3D', disabled: false },
        { color: '#F2C511', disabled: true },
      ],
      nombre: '',
      email: '',
      direccion: '',
      colegio_medico: '',
      matricula: '',
      carnet_identidad: '',
      actividad_economica: '',
      razon_social: '',
      numero_autorizacion: '',
      llave_dosificacion: '',
      leyenda: '',
      numero_inicio: '',
      mostImg: null,
      mostlogo: null,
      editTableFind: null,
      idTable: null,
      isfile1: false,
      isfile2: false,
      delItems: [],
      isAtencion: false,
      isFoto: false,
      isLogo: false,
      isReadonly: false,
      isDisabledDelete: false,
      sortDesc: true,
      sortBy: 'numero',
      sucursales: [],
      sucursalSelected: null,
      isDisableTratamiento: store.getters['main/hasPermisionListarTratamiento'],
      tipoConfiguracionFactura: true, // true: Factura Tercero, false: Factura Electrónica
      docSectorSelected: null,
      docFacturacionSelected: null,
      secuenciaSelected: null,
      tipoHojaSelected: null,
      listaDocsSector: [],
      listaDocsFacturacion: [],
      listaSecuencias: [],
      listaTipoHojas: [],
      fechaFacturaElectronica: null,
      numeroInicialFacturaElectronica: '',
      fieldsFacturaElectronica: [
        {
          key: 'numero', label: 'N°', thStyle: { width: '5%' },
        },
        {
          key: 'siat_nombre_documento_sector', label: 'Doc. Sector', class: 'text-center', thStyle: { width: '16%' },
        },
        {
          key: 'siat_nombre_tipo_documento', label: 'Tipo de Documentos', class: 'text-center', thStyle: { width: '16%' },
        },
        {
          key: 'configuracion_secuencia_name', label: 'Secuencia', class: 'text-center', thStyle: { width: '15%' },
        },
        {
          key: 'fecha_limite_emision', label: 'Fecha', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'numero_inicial', label: 'N° Inicial', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'numero_actual', label: 'N° Actual', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'configuracio_tipo_hoja_name', label: 'Tamaño', class: 'text-center', thStyle: { width: '12%' },
        },
        {
          key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
      ],
      itemsFacturaElectronica: [],
      itemFacturaElectronicaSelected: null,
      // modal config siat
      listaActividadesEconomicas: [],
      listaProductosServicios: [],
      listaMonedas: [],
      listaSucursales: [],
      listaPuntosVenta: [],
      listaUM: [],
      listaPaises: [],
      listaMedioPagosErp: [],
      listaMedioPagosSiat: [],
      listaTipoDocumentosErp: [],
      listaTipoDocumentosSiat: [],
      configuracionMedicoModal: null,
      dataTipoDocumentoMedicoModal: [],
      dataMedioPagosModal: [],
    };
  },
  created() {
    this.getAge(this.fechaNacimiento);
    this.randomColor();
    this.cargarDatosIniciales();
  },
  beforeDestroy() {
    this.resetModalHonorarioMedico();
    this.resetModalTurno1();
    this.resetModalTurno2();
  },
  methods:
  {
    ...mapActions('modalHonorarioMedico', [
      'resetModalHonorarioMedico',
    ]),
    ...mapActions('modalTurno1', [
      'resetModalTurno1',
    ]),
    ...mapActions('modalTurno2', [
      'resetModalTurno2',
    ]),
    cargarEspecialidades(lista) {
      const listaAux = lista;
      listaAux.forEach((item, index) => {
        listaAux[index].text = listaAux[index].nombre;
      });
      this.especialidades = listaAux;
    },
    SelectEspecialidades(lista, espe) {
      const listaAux = lista;
      const list = [];
      const esp = espe;
      listaAux.forEach((item, index) => {
        esp.forEach((items, indexx) => {
          if (esp[indexx].id === listaAux[index].especialidad_id) {
            list.push({
              id: esp[indexx].id,
              nombre: esp[indexx].nombre,
              text: esp[indexx].nombre,
              tiClasses: 'ti-valid',
            });
            this.especialidad_id[index] = esp[indexx].id;
          }
        });
      });
      this.especialidadesSelected = list;
    },
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const { params } = this.$route;
        const response = await axios.get(`/clinic/doctors/medico/${atob(params.id)}/edit`);
        this.handleInitialData(response);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async persistirDatos(request) {
      try {
        const { params } = this.$route;
        const response = await axios.post(`/clinic/doctors/medico/${atob(params.id)}`, request);
        util.showNotify(response.data.message, 'success');
        this.$router.push({ name: 'medico-index' });
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
    guardarMedico() {
      const form = new FormData();
      form.append('nombre', this.nombre !== null ? this.nombre : '');
      form.append('sexo', this.generoSelected !== null ? this.generoSelected : '');
      form.append('email', this.email !== null ? this.email : '');
      form.append('direccion', this.direccion !== null ? this.direccion : '');
      form.append('fecha_nacimiento', this.fullformateDate(this.fechaNacimiento));
      form.append('carnet_identidad', this.carnet_identidad !== null ? this.carnet_identidad : '');
      form.append('telefono', this.celular !== null ? this.celular : '');
      form.append('telefono_codigo', this.codigoPais !== null ? this.codigoPais : '');
      form.append('color', this.inputColor);
      form.append('numero_colegio_medico', this.colegio_medico !== null ? this.colegio_medico : '');
      form.append('numero_matricula', this.matricula !== null ? this.matricula : '');
      form.append('configuracion_factura_tercero', this.tipoConfiguracionFactura);

      if (this.tablaHonorarioMedico.length === 0) {
        form.append('medico_honorarios[]', '');
      }
      for (let index = 0; index < this.tablaHonorarioMedico.length; index += 1) {
        form.append(`medico_honorarios[${index}][id]`, this.tablaHonorarioMedico[index].id);
        form.append(`medico_honorarios[${index}][tipo_servicio_id]`, this.tablaHonorarioMedico[index].tipo_servicio_id);
        form.append(`medico_honorarios[${index}][categoria_medico_id]`, this.tablaHonorarioMedico[index].categoria_medico_id);
        form.append(`medico_honorarios[${index}][precio_costo]`, this.tablaHonorarioMedico[index].precio_costo);
        form.append(`medico_honorarios[${index}][precio_paciente]`, this.tablaHonorarioMedico[index].precio_paciente);
        form.append(`medico_honorarios[${index}][estado]`, this.tablaHonorarioMedico[index].estado);
      }
      const MODAL_TURNOS = [];
      if (this.getDataModalTurno1 !== null) {
        MODAL_TURNOS.push(this.getDataModalTurno1);
      }
      if (this.getDataModalTurno2 !== null) {
        MODAL_TURNOS.push(this.getDataModalTurno2);
      }
      if (MODAL_TURNOS.length === 0) {
        form.append('turno_medico[]', '');
      }
      for (let index = 0; index < MODAL_TURNOS.length; index += 1) {
        form.append(`turno_medico[${index}][id]`, MODAL_TURNOS[index].id);
        form.append(`turno_medico[${index}][numero_turno]`, MODAL_TURNOS[index].numero_turno);
        form.append(`turno_medico[${index}][ti_lunes]`, MODAL_TURNOS[index].lunesIngreso);
        form.append(`turno_medico[${index}][ti_martes]`, MODAL_TURNOS[index].martesIngreso);
        form.append(`turno_medico[${index}][ti_miercoles]`, MODAL_TURNOS[index].miercolesIngreso);
        form.append(`turno_medico[${index}][ti_jueves]`, MODAL_TURNOS[index].juevesIngreso);
        form.append(`turno_medico[${index}][ti_viernes]`, MODAL_TURNOS[index].viernesIngreso);
        form.append(`turno_medico[${index}][ti_sabado]`, MODAL_TURNOS[index].sabadoIngreso);
        form.append(`turno_medico[${index}][ti_domingo]`, MODAL_TURNOS[index].domingoIngreso);
        form.append(`turno_medico[${index}][ts_lunes]`, MODAL_TURNOS[index].lunesSalida);
        form.append(`turno_medico[${index}][ts_martes]`, MODAL_TURNOS[index].martesSalida);
        form.append(`turno_medico[${index}][ts_miercoles]`, MODAL_TURNOS[index].miercolesSalida);
        form.append(`turno_medico[${index}][ts_jueves]`, MODAL_TURNOS[index].juevesSalida);
        form.append(`turno_medico[${index}][ts_viernes]`, MODAL_TURNOS[index].viernesSalida);
        form.append(`turno_medico[${index}][ts_sabado]`, MODAL_TURNOS[index].sabadoSalida);
        form.append(`turno_medico[${index}][ts_domingo]`, MODAL_TURNOS[index].domingoSalida);
        form.append(`turno_medico[${index}][paciente_por_dia_lunes]`, MODAL_TURNOS[index].lunesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_martes]`, MODAL_TURNOS[index].martesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_miercoles]`, MODAL_TURNOS[index].miercolesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_jueves]`, MODAL_TURNOS[index].juevesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_viernes]`, MODAL_TURNOS[index].viernesPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_sabado]`, MODAL_TURNOS[index].sabadoPacientes);
        form.append(`turno_medico[${index}][paciente_por_dia_domingo]`, MODAL_TURNOS[index].domingoPacientes);
        form.append(`turno_medico[${index}][tiempo_atencion_lunes]`, MODAL_TURNOS[index].lunesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_martes]`, MODAL_TURNOS[index].martesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_miercoles]`, MODAL_TURNOS[index].miercolesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_jueves]`, MODAL_TURNOS[index].juevesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_viernes]`, MODAL_TURNOS[index].viernesTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_sabado]`, MODAL_TURNOS[index].sabadoTiempoAtencion);
        form.append(`turno_medico[${index}][tiempo_atencion_domingo]`, MODAL_TURNOS[index].domingoTiempoAtencion);
        form.append(`turno_medico[${index}][piso_consultorio_id_lunes]`, MODAL_TURNOS[index].lunesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_martes]`, MODAL_TURNOS[index].martesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_miercoles]`, MODAL_TURNOS[index].miercolesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_jueves]`, MODAL_TURNOS[index].juevesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_viernes]`, MODAL_TURNOS[index].viernesConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_sabado]`, MODAL_TURNOS[index].sabadoConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][piso_consultorio_id_domingo]`, MODAL_TURNOS[index].domingoConsultorioSelected?.id ?? '');
        form.append(`turno_medico[${index}][tiempo_descanso]`, MODAL_TURNOS[index].tiempoDescanso);
      }
      form.append('actividad_economica', this.actividad_economica !== null ? this.actividad_economica : '');
      form.append('razon_social', this.razon_social !== null ? this.razon_social : '');
      form.append('nit', this.nit !== null ? this.nit : '');
      form.append('ciudad_id', this.ciudadSelected !== null ? this.ciudadSelected.id : '');
      form.append('sucursal_id', this.sucursalSelected ? this.sucursalSelected : '');

      this.itemsFacturaElectronica.forEach((item, index) => {
        form.append(`factura_electronica[${index}][numero]`, item.numero);
        form.append(`factura_electronica[${index}][id]`, item.id);
        form.append(`factura_electronica[${index}][fecha_limite_emision]`, moment(item.fecha_limite_emision, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        form.append(`factura_electronica[${index}][numero_inicial]`, item.numero_inicial);
        form.append(`factura_electronica[${index}][numero_actual]`, item.numero_actual);
        // eslint-disable-next-line no-underscore-dangle
        form.append(`factura_electronica[${index}][_rowVariant]`, item._rowVariant);
        form.append(`factura_electronica[${index}][siat_documento_sector_id]`, item.siat_documento_sector_id);
        form.append(`factura_electronica[${index}][siat_cod_clasificador_documento_sector]`, item.siat_cod_clasificador_documento_sector);
        form.append(`factura_electronica[${index}][siat_nombre_documento_sector]`, item.siat_nombre_documento_sector);
        form.append(`factura_electronica[${index}][siat_documento_id]`, item.siat_documento_id);
        form.append(`factura_electronica[${index}][siat_cod_clasificador_documento]`, item.siat_cod_clasificador_documento);
        form.append(`factura_electronica[${index}][siat_nombre_tipo_documento]`, item.siat_nombre_tipo_documento);
        form.append(`factura_electronica[${index}][configuracio_tipo_hoja_id]`, item.configuracio_tipo_hoja_id);
        form.append(`factura_electronica[${index}][configuracion_secuencia_id]`, item.configuracion_secuencia_id);
      });
      form.append('siat_configuracion', this.configuracionMedicoModal ? JSON.stringify(this.configuracionMedicoModal) : '');
      form.append('siat_medio_pagos', JSON.stringify(this.dataMedioPagosModal));
      form.append('siat_tipo_documentos', JSON.stringify(this.dataTipoDocumentoMedicoModal));

      if (this.isfile1) {
        form.append('perfil', this.perfil);
      }
      if (this.isfile2) {
        form.append('logo', this.fileUpload);
      }
      const datas = {
        especialidad_id: this.especialidad_id,
        diasAtencion: this.diasAtencion,
        factura: this.listaTabla,
      };

      this.serializeAll(form, datas);
      this.persistirDatos(form);
    },
    cargarHonorariosMedicos(lista) {
      const aux = lista;
      aux.forEach((item, index) => {
        aux[index].customId = item.id;
        aux[index].servicio = this.listaServicios.find((serv) => (
          serv.id === aux[index].tipo_servicio_id
        )).nombre;
        aux[index].categoria = this.listaCategorias.find((cat) => (
          cat.id === aux[index].categoria_medico_id
        )).nombre;
        aux[index].estado = true;
      });
      this.tablaHonorarioMedico = aux;
    },
    cargarModalesTurnos(turnos) {
      if (turnos.length === 0) {
        return;
      }
      const TURNO_1 = turnos.find((el) => el.numero_turno === 1) ?? null;
      const TURNO_2 = turnos.find((el) => el.numero_turno === 2) ?? null;
      if (TURNO_1 !== null) {
        this.loadModalTurno1(TURNO_1);
      }
      if (TURNO_2 !== null) {
        this.loadModalTurno2(TURNO_2);
      }
    },
    handleInitialData(response) {
      const { medico, especialidad } = response.data.data;
      const confTer = response.data.data.facturacion_terceros;
      const dAtencion = response.data.data.dias_atencion;
      const cFactura = response.data.data.configuracion_factura;
      const espMedico = response.data.data.especialidad_medico;
      const { sucursales } = response.data.data;
      const { ciudades } = response.data.data;
      const TIPO_SERVICIOS = response.data.data.tipo_servicios;
      const CATEGORIA_MEDICO = response.data.data.categoria_medico;
      const LISTA_HONORARIO_MEDICO = response.data.data.honorario_medico;
      const PISO_CONSULTORIO = response.data.data.piso_consultorio;
      const TURNOS_MEDICO = response.data.data.turno_meidco;
      const TIPO_DOCS_FACTURADOR = response.data.data.siat_tipo_documentos_facturacion;

      this.listTablasCollection(cFactura);
      this.SelectEspecialidades(espMedico, especialidad);
      this.cargarEspecialidades(especialidad);
      this.nombre = medico.nombre;
      this.email = medico.email;
      this.direccion = medico.direccion;
      this.getAge(medico.fecha_nacimiento);
      this.fechaNacimiento = this.formatearDate(medico.fecha_nacimiento);
      this.colegio_medico = medico.numero_colegio_medico;
      this.generoSelected = medico.sexo;
      this.matricula = medico.numero_matricula;
      this.carnet_identidad = medico.carnet_identidad;
      this.codigoPais = medico.telefono_codigo;
      this.celular = medico.telefono;
      this.inputColor = medico.color;
      if (medico.perfil !== '' && medico.perfil !== null) {
        this.mostImg = process.env.VUE_APP_BASE + medico.perfil;
        this.isFoto = true;
      }
      this.tipoConfiguracionFactura = medico.configuracion_factura_tercero;

      this.actividad_economica = confTer.actividad_economica;
      this.razon_social = confTer.razon_social;
      this.nit = confTer.nit;
      this.listaCiudades = ciudades;
      this.ciudadSelected = ciudades.find((el) => el.id === confTer.ciudad) ?? null;
      if (confTer.logo !== '' && confTer.logo !== null) {
        this.mostlogo = process.env.VUE_APP_BASE + confTer.logo;
        this.isLogo = true;
      }
      this.diasAtencion = {
        primerTurno: {
          lunes: {
            clave: 'lunes',
            estado: dAtencion.pti_lunes !== null,
            ingreso: dAtencion.pti_lunes !== null ? dAtencion.pti_lunes : '',
            salida: dAtencion.pts_lunes !== null ? dAtencion.pts_lunes : '',
            totalTiempo: this.cargaRestarHora(dAtencion.pti_lunes, dAtencion.pts_lunes),
          },
          martes: {
            clave: 'martes',
            estado: dAtencion.pti_martes !== null,
            ingreso: dAtencion.pti_martes !== null ? dAtencion.pti_martes : '',
            salida: dAtencion.pts_martes !== null ? dAtencion.pts_martes : '',
            totalTiempo: this.cargaRestarHora(dAtencion.pti_martes, dAtencion.pts_martes),
          },
          miercoles: {
            clave: 'miercoles',
            estado: dAtencion.pti_miercoles !== null,
            ingreso: dAtencion.pti_miercoles !== null ? dAtencion.pti_miercoles : '',
            salida: dAtencion.pts_miercoles !== null ? dAtencion.pts_miercoles : '',
            totalTiempo: this.cargaRestarHora(dAtencion.pti_miercoles, dAtencion.pts_miercoles),
          },
          jueves: {
            clave: 'jueves',
            estado: dAtencion.pti_jueves !== null,
            ingreso: dAtencion.pti_jueves !== null ? dAtencion.pti_jueves : '',
            salida: dAtencion.pts_jueves !== null ? dAtencion.pts_jueves : '',
            totalTiempo: this.cargaRestarHora(dAtencion.pti_jueves, dAtencion.pts_jueves),
          },
          viernes: {
            clave: 'viernes',
            estado: dAtencion.pti_viernes !== null,
            ingreso: dAtencion.pti_viernes !== null ? dAtencion.pti_viernes : '',
            salida: dAtencion.pts_viernes !== null ? dAtencion.pts_viernes : '',
            totalTiempo: this.cargaRestarHora(dAtencion.pti_viernes, dAtencion.pts_viernes),
          },
          sabado: {
            clave: 'sabado',
            estado: dAtencion.pti_sabado !== null,
            ingreso: dAtencion.pti_sabado !== null ? dAtencion.pti_sabado : '',
            salida: dAtencion.pts_sabado !== null ? dAtencion.pts_sabado : '',
            totalTiempo: this.cargaRestarHora(dAtencion.pti_sabado, dAtencion.pts_sabado),
          },
          domingo: {
            clave: 'domingo',
            estado: dAtencion.pti_domingo !== null,
            ingreso: dAtencion.pti_domingo !== null ? dAtencion.pti_domingo : '',
            salida: dAtencion.pts_domingo !== null ? dAtencion.pts_domingo : '',
            totalTiempo: this.cargaRestarHora(dAtencion.pti_domingo, dAtencion.pts_domingo),
          },
        },
        segundoTurno: {
          lunes: {
            clave: 'lunes',
            estado: dAtencion.sti_lunes !== null,
            ingreso: dAtencion.sti_lunes !== null ? dAtencion.sti_lunes : '',
            salida: dAtencion.sts_lunes !== null ? dAtencion.sts_lunes : '',
            totalTiempo: this.cargaRestarHora(dAtencion.sti_lunes, dAtencion.sts_lunes),
          },
          martes: {
            clave: 'martes',
            estado: dAtencion.sti_martes !== null,
            ingreso: dAtencion.sti_martes !== null ? dAtencion.sti_martes : '',
            salida: dAtencion.sts_martes !== null ? dAtencion.sts_martes : '',
            totalTiempo: this.cargaRestarHora(dAtencion.sti_martes, dAtencion.sts_martes),
          },
          miercoles: {
            clave: 'miercoles',
            estado: dAtencion.sti_miercoles !== null,
            ingreso: dAtencion.sti_miercoles !== null ? dAtencion.sti_miercoles : '',
            salida: dAtencion.sts_miercoles !== null ? dAtencion.sts_miercoles : '',
            totalTiempo: this.cargaRestarHora(dAtencion.sti_miercoles, dAtencion.sts_miercoles),
          },
          jueves: {
            clave: 'jueves',
            estado: dAtencion.sti_jueves !== null,
            ingreso: dAtencion.sti_jueves !== null ? dAtencion.sti_jueves : '',
            salida: dAtencion.sts_jueves !== null ? dAtencion.sts_jueves : '',
            totalTiempo: this.cargaRestarHora(dAtencion.sti_jueves, dAtencion.sts_jueves),
          },
          viernes: {
            clave: 'viernes',
            estado: dAtencion.sti_viernes !== null,
            ingreso: dAtencion.sti_viernes !== null ? dAtencion.sti_viernes : '',
            salida: dAtencion.sts_viernes !== null ? dAtencion.sts_viernes : '',
            totalTiempo: this.cargaRestarHora(dAtencion.sti_viernes, dAtencion.sts_viernes),
          },
          sabado: {
            clave: 'sabado',
            estado: dAtencion.sti_sabado !== null,
            ingreso: dAtencion.sti_sabado !== null ? dAtencion.sti_sabado : '',
            salida: dAtencion.sts_sabado !== null ? dAtencion.sts_sabado : '',
            totalTiempo: this.cargaRestarHora(dAtencion.sti_sabado, dAtencion.sts_sabado),
          },
          domingo: {
            clave: 'domingo',
            estado: dAtencion.sti_domingo !== null,
            ingreso: dAtencion.sti_domingo !== null ? dAtencion.sti_domingo : '',
            salida: dAtencion.sts_domingo !== null ? dAtencion.sts_domingo : '',
            totalTiempo: this.cargaRestarHora(dAtencion.sti_domingo, dAtencion.sts_domingo),
          },
        },
      };
      this.sucursalSelected = confTer.sucursal_id;
      sucursales.forEach((item) => {
        this.sucursales.push({ value: item.id, text: item.nombre });
      });

      if (Array.isArray(TIPO_DOCS_FACTURADOR)) {
        this.listaDocsFacturacion = TIPO_DOCS_FACTURADOR;
      }
      this.listaDocsSector = response.data.data.siat_documento_por_sectors;
      this.listaSecuencias = response.data.data.configuracion_tiempo_secuencias;
      this.listaTipoHojas = response.data.data.configuracion_tipo_hoja.filter((el) => el.id === 3 /* 3: Rollo */);
      this.cargarItemsFacturaElectronica(response.data.data.configuracion_factura_electronica);

      // datos para el modal configuracion del medico
      this.configuracionMedicoModal = response.data.data.siat_configuracion_medico;
      this.cargarDataMedioPagosModal(response.data.data.medio_pago_siat_medicos);
      this.cargarDataTipoDocMedicoModal(response.data.data.tipo_documento_siat_medicos);

      this.listaActividadesEconomicas = response.data.data.siat_actividad_economicas;
      this.listaProductosServicios = response.data.data.siat_producto_servicios;
      this.listaMonedas = response.data.data.siat_monedas;
      this.listaSucursales = response.data.data.siat_sucursales;
      this.listaPuntosVenta = response.data.data.siat_punto_ventas;
      this.listaUM = response.data.data.siat_unidad_medidas;
      this.listaPaises = response.data.data.siat_pais;
      this.listaMedioPagosErp = response.data.data.medio_pagos;
      this.listaMedioPagosSiat = response.data.data.siat_medio_pagos;
      this.listaTipoDocumentosSiat = response.data.data.siat_tipo_documentos;
      this.listaTipoDocumentosErp = response.data.data.tipo_documentos;

      this.listaServicios = TIPO_SERVICIOS;
      this.listaCategorias = CATEGORIA_MEDICO;
      this.listaConsultorio1 = PISO_CONSULTORIO;
      this.listaConsultorio2 = PISO_CONSULTORIO;
      this.cargarHonorariosMedicos(LISTA_HONORARIO_MEDICO);
      this.cargarModalesTurnos(TURNOS_MEDICO);
    },
    serializeAll(form, data) {
      const datas = data;
      Object.entries(datas).forEach((items) => {
        if (Array.isArray(items[1]) && items[0] === 'especialidad_id') {
          Object.entries(items[1]).forEach((d, index) => {
            form.append(`especialidad_id[${[index]}]`, d[1]);
          });
        } else if (Array.isArray(items[1]) && items[0] === 'factura') {
          Object.entries(items[1]).forEach((aux, k) => {
            Object.entries(aux[1]).forEach((m) => {
              if (m[0] === 'fecha_limite_emision') {
                form.append(`factura[${k}][${m[0]}]`, this.formatearDateReverse(m[1]));
              } else {
                form.append(`factura[${k}][${m[0]}]`, m[1]);
              }
            });
          });
        } else if (typeof items[1] === 'object' && items[0] === 'diasAtencion') {
          Object.entries(datas.diasAtencion).forEach((d) => {
            Object.entries(d[1]).forEach((t) => {
              if (t[1].ingreso !== '') {
                form.append(`${d[0] + t[1].clave}Ingreso`, t[1].ingreso);
              }
              if (t[1].salida !== '') {
                form.append(`${d[0] + t[1].clave}Salida`, t[1].salida);
              }
            });
          });
        }
      });
    },
    cleanInput() {
      this.nombre = '';
      this.generoSelected = '';
      this.email = '';
      this.direccion = '';
      this.fechaNacimiento = '';
      this.carnet_identidad = '';
      this.celular = '';
      this.codigoPais = '';
      this.inputColor = '';
      this.colegio_medico = '';
      this.matricula = '';
      this.perfil = null;
      this.fileUpload = null;

      this.actividad_economica = '';
      this.razon_social = '';
      this.nit = '';
      this.ciudadSelected = 0;

      this.especialidad_id = [];

      this.listaTabla = [];
      this.diasAtencion = {
        primerTurno: {
          lunes: {
            clave: 'lunes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          martes: {
            clave: 'martes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          miercoles: {
            clave: 'miercoles',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          jueves: {
            clave: 'jueves',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          viernes: {
            clave: 'viernes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          sabado: {
            clave: 'sabado',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          domingo: {
            clave: 'domingo',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
        },
        segundoTurno: {
          lunes: {
            clave: 'lunes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          martes: {
            clave: 'martes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          miercoles: {
            clave: 'miercoles',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          jueves: {
            clave: 'jueves',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          viernes: {
            clave: 'viernes',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          sabado: {
            clave: 'sabado',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
          domingo: {
            clave: 'domingo',
            estado: false,
            ingreso: '',
            salida: '',
            totalTiempo: '',
          },
        },
      };
    },
    setCodigoPais() {
      this.codigoPais = this.paisSelected.codigo;
    },
    getAge(dateString) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
      }
      this.edadActual = `${age} Años`;
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarCelular() {
      if (this.celular === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.celular)) {
        this.celular = '';
      }
    },
    reValidar() {
      if (this.nit === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.nit)) {
        this.nit = '';
      }
    },
    randomColor() {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      this.inputColor = `#${randomColor}`;
    },
    cargaRestarHora(ingreso, salida) {
      if (ingreso === '' || salida === '' || ingreso === null || salida === null) {
        return '';
      }
      const minutosInicio = ingreso.split(':').splice(0, 2)
        .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
      const minutosFinal = salida.split(':').splice(0, 2)
        .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
      if (minutosFinal < minutosInicio) {
        this.isAtencion = false;
        return 'No puede ser Ingreso Menor que Salida';
      }
      const diferencia = minutosFinal - minutosInicio;
      const horas = Math.floor(diferencia / 60);
      const minutos = diferencia % 60;
      this.isAtencion = true;
      return `${horas} hora y ${minutos} minutos`;
    },
    restarHora(dia, turno) {
      if (dia.ingreso === '' || dia.salida === '') {
        this.diasAtencion[turno][dia.clave].totalTiempo = '';
        this.isAtencion = false;
        return;
      }
      // Calcula los minutos de cada hora
      const minutosInicio = dia.ingreso.split(':')
        .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
      const minutosFinal = dia.salida.split(':')
        .reduce((p, c) => parseInt(p, 10) * 60 + parseInt(c, 10));
      // Si la hora final es anterior a la hora inicial sale
      if (minutosFinal < minutosInicio) {
        this.diasAtencion[turno][dia.clave].totalTiempo = 'No puede ser Ingreso Menor que Salida';
        this.isAtencion = false;
        return;
      }
      // Diferencia de minutos
      const diferencia = minutosFinal - minutosInicio;
      // Cálculo de horas y minutos de la diferencia
      const horas = Math.floor(diferencia / 60);
      const minutos = diferencia % 60;
      this.isAtencion = true;
      this.diasAtencion[turno][dia.clave].totalTiempo = `${horas} hora y ${minutos} minutos`;
    },
    validarEspecialidad(newEs) {
      if (newEs.length !== 0) {
        const item = [...newEs].pop();
        const longitud = this.especialidades.filter((obj) => obj.text === item.text).length;
        if (longitud !== 0) {
          this.especialidad_id = newEs.map((s) => s.id);
          this.especialidadesSelected = newEs;
        } else {
          newEs.pop();
          this.especialidad_id = newEs.map((s) => s.id);
        }
      } else {
        this.especialidad_id = [];
        newEs.pop();
      }
    },
    formatearDate(fecha) {
      const dat = new Date(fecha);
      return new Date(dat.setDate(dat.getDate() + 1));
    },
    formatearDateList(fecha) {
      return fecha.split('-').reverse().join('/');
    },
    formatearDateReverse(fecha) {
      return fecha.split('/').reverse().join('-');
    },
    fullformateDate(fecha) {
      const day = fecha.getDate();
      const month = fecha.getMonth() + 1;
      const year = fecha.getFullYear();
      return `${year}-${month}-${day}`;
    },
    listTablas() {
      if (this.editTableFind === null) {
        this.listaTabla.push({
          numero: this.listaTabla.length + 1,
          id: '',
          numero_autorizacion: this.numero_autorizacion,
          llave_dosificacion: this.llave_dosificacion,
          leyenda: this.leyenda,
          fecha_limite_emision: this.fechaFacturacionTerceros,
          numero_inicial: this.numero_inicio,
          numero_actual: 'S/M',
          isEditData: false,
          _rowVariant: 'success',
          client: 'client',
        });
      } else {
        this.listaTabla[this.editTableFind].id = this.idTable;
        this.listaTabla[this.editTableFind].numero_autorizacion = this.numero_autorizacion;
        this.listaTabla[this.editTableFind].llave_dosificacion = this.llave_dosificacion;
        this.listaTabla[this.editTableFind].leyenda = this.leyenda;
        this.listaTabla[this.editTableFind].fecha_limite_emision = this.fechaFacturacionTerceros;
        this.listaTabla[this.editTableFind].numero_inicial = this.numero_inicio;
        this.listaTabla[this.editTableFind].numero_actual = 'S/M';
        this.listaTabla[this.editTableFind].isEditData = false;
        // eslint-disable-next-line no-underscore-dangle
        this.listaTabla[this.editTableFind]._rowVariant = 'success';
        this.editTableFind = null;
        this.idTable = null;
      }
      this.numero_autorizacion = '';
      this.llave_dosificacion = '';
      this.leyenda = '';
      this.fechaFacturacionTerceros = '';
      this.numero_inicio = '';
      this.isReadonly = true;
      this.isDisabledDelete = false;
    },
    listTablasCollection(list) {
      list.forEach((item, index) => {
        this.listaTabla.push({
          numero: this.listaTabla.length + 1,
          id: list[index].id,
          numero_autorizacion: list[index].numero_autorizacion,
          llave_dosificacion: list[index].llave_dosificacion,
          leyenda: list[index].leyenda,
          fecha_limite_emision: this.formatearDateList(list[index].fecha_limite_emision),
          numero_inicial: list[index].numero_inicial,
          numero_actual: list[index].numero_factura ? list[index].numero_factura : 'S/M',
          isEditData: list[index].estado === 0,
          _rowVariant: list[index].estado === 1 ? 'success' : 'danger',
          client: 'server',
        });
        this.isReadonly = list[index].estado === 1;
      });
    },
    editItems(index) {
      this.numero_autorizacion = this.listaTabla[index].numero_autorizacion;
      this.llave_dosificacion = this.listaTabla[index].llave_dosificacion;
      this.leyenda = this.listaTabla[index].leyenda;
      this.fechaFacturacionTerceros = this.listaTabla[index].fecha_limite_emision;
      this.numero_inicio = this.listaTabla[index].numero_inicial;
      this.editTableFind = index;
      this.idTable = this.listaTabla[index].id;
      this.isReadonly = false;
      this.isDisabledDelete = true;
    },
    deleteItems(index) {
      if (this.listaTabla[index].client === 'client') {
        this.listaTabla.splice(index, 1);
        this.isDisabledDelete = false;
        this.isReadonly = false;
      } else {
        this.delItems.push(this.listaTabla[index].id);
        this.listaTabla[index].isEditData = true;
        this.isDisabledDelete = false;
        this.isReadonly = false;
        // eslint-disable-next-line no-underscore-dangle
        this.listaTabla[index]._rowVariant = 'danger';
      }
    },
    perfils(e) {
      const file = e.target.files[0];
      this.perfil = file;
      this.isfile1 = true;
      const render = new FileReader();
      render.onload = (s) => {
        this.mostImg = s.target.result;
        this.isFoto = true;
      };
      render.readAsDataURL(file);
    },
    fileUploads(e) {
      const file = e.target.files[0];
      this.fileUpload = file;
      this.isfile2 = true;
      const render = new FileReader();
      render.onload = (s) => {
        this.mostlogo = s.target.result;
        this.isLogo = true;
      };
      render.readAsDataURL(file);
    },
    openModalHonorario() {
      this.$bvModal.show('modal-honorarioMedico');
    },
    openModalTurno1() {
      this.$bvModal.show('modal-turno1');
    },
    openModalTurno2() {
      this.$bvModal.show('modal-turno2');
    },
    cargarItemsFacturaElectronica(lista) {
      this.itemsFacturaElectronica = lista.map((el, index) => {
        const docSector = this.listaDocsSector.find((item) => item.id === el.siat_documento_sector_id) ?? null;
        const tipoDocumento = this.listaDocsFacturacion.find((item) => item.id === el.siat_documento_id) ?? null;
        const tipoSecuencia = this.listaSecuencias.find((item) => item.id === el.configuracion_secuencia_id) ?? null;
        const tipoHoja = this.listaTipoHojas.find((item) => item.id === el.configuracio_tipo_hoja_id) ?? null;
        return {
          numero: index + 1,
          id: el.id,
          fecha_limite_emision: moment(el.fecha_limite_emision, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          numero_inicial: el.numero_inicial,
          numero_actual: el.numero_factura ? el.numero_factura : 'S/M',
          _rowVariant: el.estado === 1 ? 'success' : 'danger',
          siat_documento_sector_id: el.siat_documento_sector_id,
          siat_cod_clasificador_documento_sector: el.siat_cod_clasificador_documento_sector,
          siat_nombre_documento_sector: docSector ? docSector.emisor_descripcion : null,
          siat_documento_id: el.siat_documento_id,
          siat_cod_clasificador_documento: el.siat_cod_clasificador_documento,
          siat_nombre_tipo_documento: tipoDocumento ? tipoDocumento.descripcion : null,
          configuracio_tipo_hoja_id: el.configuracio_tipo_hoja_id,
          configuracio_tipo_hoja_name: tipoHoja ? tipoHoja.nombre : null,
          configuracion_secuencia_id: el.configuracion_secuencia_id,
          configuracion_secuencia_name: tipoSecuencia ? tipoSecuencia.nombre : null,
        };
      });
    },
    cargarDataMedioPagosModal(data) {
      this.dataMedioPagosModal = data;
    },
    cargarDataTipoDocMedicoModal(data) {
      this.dataTipoDocumentoMedicoModal = data;
    },
    addOrUpdateConfigFacturaElectronica() {
      if (this.docSectorSelected === null) {
        util.showNotify('El campo Doc. Sector es requerido.', 'warn');
        return;
      }
      if (this.docFacturacionSelected === null) {
        util.showNotify('El campo Tipo de Documentos es requerido.', 'warn');
        return;
      }
      if (this.secuenciaSelected === null) {
        util.showNotify('El campo Secuencia es requerido.', 'warn');
        return;
      }
      if (this.fechaFacturaElectronica === null) {
        util.showNotify('La fecha de emisión es requerido.', 'warn');
        return;
      }
      if (this.numeroInicialFacturaElectronica === '') {
        util.showNotify('El número inicial es requerido.', 'warn');
        return;
      }
      if (this.tipoHojaSelected === null) {
        util.showNotify('El campo Tamaño es requerido.', 'warn');
        return;
      }
      if (this.itemFacturaElectronicaSelected !== null) {
        const INDEX = this.itemsFacturaElectronica.findIndex((el) => (
          el.id === this.itemFacturaElectronicaSelected.id
        ));
        this.itemsFacturaElectronica[INDEX].fecha_limite_emision = this.fechaFacturaElectronica;
        this.itemsFacturaElectronica[INDEX].numero_inicial = this.numeroInicialFacturaElectronica;
        this.itemsFacturaElectronica[INDEX].siat_documento_sector_id = this.docSectorSelected.id;
        this.itemsFacturaElectronica[INDEX].siat_cod_clasificador_documento_sector = this.docSectorSelected.siat_cod_clasificador;
        this.itemsFacturaElectronica[INDEX].siat_nombre_documento_sector = this.docSectorSelected.emisor_descripcion;
        this.itemsFacturaElectronica[INDEX].siat_documento_id = this.docFacturacionSelected.id;
        this.itemsFacturaElectronica[INDEX].siat_cod_clasificador_documento = this.docFacturacionSelected.cod_clasificador;
        this.itemsFacturaElectronica[INDEX].siat_nombre_tipo_documento = this.docFacturacionSelected.descripcion;
        this.itemsFacturaElectronica[INDEX].configuracion_secuencia_id = this.secuenciaSelected.id;
        this.itemsFacturaElectronica[INDEX].configuracion_secuencia_name = this.secuenciaSelected.nombre;
        this.itemsFacturaElectronica[INDEX].configuracio_tipo_hoja_id = this.tipoHojaSelected.id;
        this.itemsFacturaElectronica[INDEX].configuracio_tipo_hoja_name = this.tipoHojaSelected.nombre;

        this.itemFacturaElectronicaSelected = null;
      } else {
        this.itemsFacturaElectronica.push({
          numero: this.itemsFacturaElectronica.length + 1,
          id: 0,
          fecha_limite_emision: this.fechaFacturaElectronica,
          numero_inicial: this.numeroInicialFacturaElectronica,
          numero_actual: 'S/M',
          _rowVariant: 'success',
          siat_documento_sector_id: this.docSectorSelected.id,
          siat_cod_clasificador_documento_sector: this.docSectorSelected.siat_cod_clasificador,
          siat_nombre_documento_sector: this.docSectorSelected.emisor_descripcion,
          siat_documento_id: this.docFacturacionSelected.id,
          siat_cod_clasificador_documento: this.docFacturacionSelected.cod_clasificador,
          siat_nombre_tipo_documento: this.docFacturacionSelected.descripcion,
          configuracion_secuencia_id: this.secuenciaSelected.id,
          configuracion_secuencia_name: this.secuenciaSelected.nombre,
          configuracio_tipo_hoja_id: this.tipoHojaSelected.id,
          configuracio_tipo_hoja_name: this.tipoHojaSelected.nombre,
        });
      }

      this.clearFormFacturaElectronica();
    },
    editItemFacturaElectronica(item) {
      this.itemFacturaElectronicaSelected = item;
      this.docSectorSelected = this.listaDocsSector.find((el) => el.id === item.siat_documento_sector_id) ?? null;
      this.docFacturacionSelected = this.listaDocsFacturacion.find((el) => el.id === item.siat_documento_id) ?? null;
      this.secuenciaSelected = this.listaSecuencias.find((el) => el.id === item.configuracion_secuencia_id) ?? null;
      this.fechaFacturaElectronica = item.fecha_limite_emision;
      this.numeroInicialFacturaElectronica = item.numero_inicial;
      this.tipoHojaSelected = this.listaTipoHojas.find((el) => el.id === item.configuracio_tipo_hoja_id) ?? null;
    },
    deleteItemFacturaElectronica(index) {
      if (this.itemsFacturaElectronica[index].id === 0) {
        this.itemsFacturaElectronica.splice(index, 1);
      } else {
        // eslint-disable-next-line no-underscore-dangle
        this.itemsFacturaElectronica[index]._rowVariant = 'danger';
      }
    },
    clearFormFacturaElectronica() {
      this.docSectorSelected = null;
      this.docFacturacionSelected = null;
      this.secuenciaSelected = null;
      this.fechaFacturaElectronica = null;
      this.numeroInicialFacturaElectronica = '';
      this.tipoHojaSelected = null;
    },
    ...mapMutations('modalTurno1', [
      'loadModalTurno1',
    ]),
    ...mapMutations('modalTurno2', [
      'loadModalTurno2',
    ]),
  },
  computed: {
    labelTipoConfiguracion() {
      if (this.tipoConfiguracionFactura) {
        return 'Factura Tercero';
      }
      return 'Factura Electrónica';
    },
    ...mapFields('modalHonorarioMedico', [
      'tablaHonorarioMedico',
      'listaServicios',
      'listaCategorias',
    ]),
    ...mapFields('modalTurno1', {
      listaConsultorio1: 'listaConsultorio',
    }),
    ...mapFields('modalTurno2', {
      listaConsultorio2: 'listaConsultorio',
    }),
    ...mapGetters('modalHonorarioMedico', [
      'tablaHonorarioMedicoFiltered',
    ]),
    ...mapGetters('modalTurno1', [
      'turno1Validate',
      'getDataModalTurno1',
    ]),
    ...mapGetters('modalTurno2', [
      'turno2Validate',
      'getDataModalTurno2',
    ]),
    ...mapGetters('main', [
      'hasPermissionMedicoTercero',
    ]),
    filteredItems() {
      return this.especialidades.filter((i) => (
        i.text.toLowerCase().indexOf(this.especialidadInput.toLowerCase()) !== -1));
    },
    imagenPerfil() {
      return this.mostImg;
    },
    imagenLogo() {
      return this.mostlogo;
    },
    isDisabledFormFacturaElectronica() {
      let disabled = false;
      if (this.itemsFacturaElectronica.length === 0) {
        disabled = false;
      } else {
        this.itemsFacturaElectronica.forEach((el) => {
          // eslint-disable-next-line no-underscore-dangle
          if (el._rowVariant === 'success') {
            disabled = true;
          }
        });
      }
      if (this.itemFacturaElectronicaSelected !== null) {
        disabled = false;
      }
      return disabled;
    },
    getClassForBtnSyncSiat() {
      if (this.configuracionMedicoModal === null
      || this.dataTipoDocumentoMedicoModal.length === 0
      || this.dataMedioPagosModal.length === 0) {
        return 'btn-secondary';
      }
      return 'btn-success';
    },
  },
};
</script>

<style>
.tabs_custom_border{
  border-bottom: 1px solid #30c573 !important;
}
.tab_custom_border {
  border: 1px solid transparent;
  background: rgba(48, 197, 115, 0.15) !important;
  border-color: #30c573 #30c573 #fff !important;
}
</style>
<style scoped>
.bg-custom-gray {
  background: rgba(0, 0, 0, 0.1);
}
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.bg-skyblue
{
  background: #61c6f2 !important;
}
.bg-gray-custom {
  background: #eeeeee;
}
.rounded-box
{
  border-radius: 15px 15px;
}
.file-upload{
  display: none;
}
.custom-file-upload {
  margin-bottom: 0;
}
</style>
